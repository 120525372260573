import React from "react";
import * as styles from '../assets/newevent.module.css'
import { Card ,Container} from "react-bootstrap";
import Layout from '../components/layout'
import { graphql ,Link} from "gatsby"



const Newevent = ({data}) =>{
    console.log(data)

    const event = data.allStrapiNewEvents.nodes[0].NewEventComponent
    
    
    let newevnts = event.map((element,index)=>{
        let getstartdate = new Date(element.StartDateAndTime)
       
        let startmonth = getstartdate.toLocaleString('default', { month: 'long' })
        let startdate = getstartdate.getDate() <=9 ? '0' + getstartdate.getDate() : getstartdate.getDate()
        let startyear = getstartdate.getFullYear()

        let getenddate = new Date(element.EndDateAndTime)


        return(
            <Card className={styles.card}> 
                <div className="row">
                    <div className="col-2" id={styles.startdate}>
                        <div  style={{border:"1px solid black"}}>
                        <div className={styles.startmonth}><span>{startmonth}</span></div>
                        <div className={styles.startdate}><span>{startdate}</span></div>
                        <div className={styles.startyear}><span>{startyear}</span></div>
                        </div>
                    </div> 
                    <div className="col">
                        <div className={styles.rightdata}>
                            <p className={styles.p}>{element.link}</p>
                            <Link to={`/${element.link}`} className={element.PageContent != null ? styles.readmore : styles.disablereadmore} state={{index: index}} >Read more</Link><br/>
                            <div className={styles.time}>
                                <span><b>Starts :</b> {getstartdate.toUTCString()}</span> &nbsp; &nbsp;
                                {element.EndDateAndTime == null ? '':
                                <span><b>Ends</b> : {getenddate.toUTCString()}</span>}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Card>
        )
    })
     
    return(
        <Layout>
        <Container>
            <h5 style={{textAlign:"center",fontWeight:"600",fontSize:"30px",marginTop:"20px"}}>{data.allStrapiNewEvents.nodes[0].Title}</h5>
            <div className="row">
                {newevnts}
            </div>
        </Container>
        </Layout>
       )
}

export const query = graphql`
  {
    allStrapiNewEvents(sort: {fields: NewEventComponent___StartDateAndTime, order: ASC}) {
      nodes {
        Title
        NewEventComponent {
          link
          StartDateAndTime
          EndDateAndTime
          PageContent
        }
      }
    }
  }
`

export default Newevent